<template>
  <div class="container pt-5 pb-5 d-flex align-items-start justify-content-center">
    <div class="bg-white p-5 rounded-lg">
      <h3 class="mb-4 mb-sm-5">{{ $t('task_or_job_selector.title') }}</h3>
      <div>
        <div class="row">
          <div class="flex flex-col flex-sm-row w-100 justify-content-center mx-sm-5">
            <b-button class="task-or-job-btn flex-grow-1 col-sm-6" :variant="selected === 'job' ? 'dark' : 'outline-dark'" @click="selected = 'job'">
              <div class="d-flex flex-col px-4 py-4 w-100">
                <div class="d-flex py-3 mb-4 justify-content-between">
                  <i class="fa-solid fa-user-tie fa-2xl"></i>
                  <i v-if="selected === 'job'" class="fa-solid fa-circle-check fa-xl"></i>
                  <i v-else class="fa-regular fa-circle fa-xl"></i>
                </div>
                <h5 class="w-100 text-wrap text-left mb-0">
                  {{ $t('task_or_job_selector.job') }}
                </h5>
              </div>
            </b-button>
            <b-button :variant="selected === 'tasks' ? 'dark' : 'outline-dark'" class="task-or-job-btn ml-sm-4 mt-2 mt-sm-0 flex-grow-1 col-sm-6" @click="selected = 'tasks'">
              <div class="d-flex flex-col px-4 py-4 w-100">
                <div class="d-flex py-3 mb-4 justify-content-between">
                  <i class="fa-solid fa-user-pen fa-2xl"></i>
                  <i v-if="selected === 'tasks'" class="fa-solid fa-circle-check fa-xl"></i>
                  <i v-else class="fa-regular fa-circle fa-xl"></i>
                </div>
                <h5 class="w-100 text-wrap text-left mb-0">
                  {{ $t('task_or_job_selector.task') }}
                </h5>
              </div>
            </b-button>
          </div>
        </div>
      </div>
      <div class="pt-2 pb-2 d-flex align-items-center justify-content-center mt-4 mt-sm-5">
        <b-button :disabled="!selected" class="btn btn-primary px-4" @click="continueClicked()">{{ $t('task_or_job_selector.continue') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TaskOrJob',
  data () {
    return {
      selected: null
    }
  },
  props: {
    referrer: {
      type: String,
      required: false
    }
  },
  methods: {
    continueClicked () {
      if (!this.hasRole('ROLE_SELLER') && !this.hasRole('ROLE_PRE_SELLER') && this.selected === 'tasks') {
        this.$router.push({ name: 'BecomeSeller' })
      } else {
        this.$router.push('/my-account/' + this.selected + '/create')
        this.$tracker.tagEvent('taskOrJobConfirmed', {
          'confirmedType': this.selected,
          'referrer': this.referrer || null
        })
      }
    }
  },
  computed: {
    ...mapGetters(['hasRole'])
  }
}
</script>

<style scoped>
.task-or-job-btn {
  border-radius: 0.7rem;
}
</style>
