<template>
  <div class="card">
    <div class="card-header bg-transparent d-flex justify-content-between">
      <div><strong>{{ $t('job.card.posted_by') }}</strong><br class="d-sm-none" /> {{ job.user.username }}</div>
      <div v-if="job.date_expires !== null"><strong>{{ $t('job.card.date_expired') }}</strong><br class="d-sm-none" /> {{ moment(job.date_expires).fromNow() }}</div>
      <div><strong>{{ $t('job.card.budget') }}</strong><br class="d-sm-none" /> {{ displayPrice(job.price_from, 'USD') }} - {{ displayPrice(job.price_to) }}</div>
    </div>
    <div class="card-body row text-left">
      <div class="col-12" :class="{ 'col-lg-8 col-sm-7' : job.job_assets.length > 0 }">
        <h3 v-if="isPreview" class="text-left mb-3">
          {{ job.title }}
        </h3>
        <router-link v-else :to="{ name: 'jobs_view', params: { hash: job.hash }}" class="text-dark">
          <h3 class="text-left mb-3">
            {{ job.title }}
          </h3>
        </router-link>
        <div class="job-scroll-description mb-4 mb-sm-0" v-html="this.$sanitize(job.description, {
          allowedTags: ['br', 'strong', 'p', 'ul', 'li', 'u', 'ol', 'em', 'i']
        })"></div>
      </div>
      <div class="col-12 col-lg-4 col-sm-5" v-if="job.job_assets.length > 0">
        <carousel class="w-100" :images="buildImages" :taskHash="job.hash"/>
      </div>
    </div>
    <div class="card-footer bg-transparent border-top-0 flex-col flex-sm-row justify-content-sm-end">
    <button-action v-if="!isPreview"
                     :job="job"
                     :is-seller="isSeller"
                     @job-contact-client="contact"
                     @job-open-offer="openOffer"
                     :button-display="{ viewJob: this.displayJobButton }"/>
    </div>
  </div>
</template>

<script>
import carousel from '../Global/CardCarousel'
import helper from '../../helper'
import ButtonAction from './ButtonAction'

export default {
  name: 'JobCard',
  components: { ButtonAction, carousel },
  props: {
    job: {
      type: Object,
      required: true
    },
    isSeller: {
      type: Boolean,
      required: true
    },
    displayJobButton: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    },
    isPreview: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    }
  },
  methods: {
    displayPrice (price) {
      return helper.getDisplayPrice(price, 'USD')
    },
    localeDateFormat (date) {
      return (new Date(date)).toLocaleDateString()
    },
    openOffer: function (job) {
      this.$emit('job-open-offer', job)
    },
    contact: function (data) {
      this.$emit('job-contact-client', data)
    }
  },
  computed: {
    buildImages () {
      return this.job.job_assets.map(function (asset) {
        return { 'src': helper.formatCloudinaryUrl(asset.path, 'task'), 'type': asset.type }
      })
    },
    getLoggedInUser () {
      return localStorage.getItem('username')
    },
    isOwner () {
      return this.job.user.username === this.getLoggedInUser
    }
  }
}
</script>
