<template>
  <b-modal id="job-offer-modal"
           title-tag="h3"
           :title="$t('job.offer.title')"
           title-class="text-center w-100"
           size="lg"
           :ok-title="$t('job.offer.button.ok')"
           @ok="handleSubmit"
           @shown="init"
           @hidden="reset"
           header-class="border-0"
           footer-class="border-0">

    <div class="mx-3 pl-3 pr-3 pb-3 pt-0">
      <p class="text-center lead">{{ $t('job.offer.description', { 'job_title': this.job.title }) }}</p>
      <div v-if="errors.length" class="alert alert-danger">
        <div v-for="error in errors" :key="error">{{ error }}</div>
      </div>
      <div class="form-row text-left">
        <div class="form-group col-12 col-lg-6">
          <label for="form-job-offer-price">{{ $t('job.offer.price.label') }}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="number"
                   min="1"
                   max="500.00"
                   step="00.01"
                   class="form-control"
                   id="form-job-offer-price"
                   :placeholder="$t('job.offer.price.placeholder')"
                   v-model="price"
                   v-bind:class="{ 'border-danger': validation.price}"
                   required
            >
          </div>
          <small class="form-text text-muted">{{ $t('job.offer.price.info', { 'budget_from': this.job.price_from, 'budget_to': this.job.price_to }) }}</small>
        </div>
        <div class="form-group col-12 col-lg-6">
          <label for="form-job-offer-revisions">{{ $t('job.offer.revisions.label') }}</label>
          <input type="number"
                 step="1"
                 min="1"
                 class="form-control"
                 id="form-job-offer-revisions"
                 :placeholder="$t('job.offer.revisions.placeholder')"
                 v-model="revisions"
                 v-bind:class="{ 'border-danger': validation.revisions}"
                 required
          >
          <small class="form-text text-muted">{{ $t('job.offer.revisions.info') }}</small>
        </div>
      </div>
      <div class="form-row">
        <label for="form-job-offer-delivery">{{ $t('job.offer.delivery.label') }}</label>
        <div class="col-12">
          <div class="range-wrap">
            <div class="range-value" id="form-job-offer-delivery-display">
              <span class="bubble">{{ $tc('job.offer.delivery.slide.value', delivery) }}</span>
            </div>
            <input
              type="range"
              min="1"
              max="30"
              class="range slider-progress m-0 p-0 w-100"
              id="form-job-offer-delivery"
              @input="changeSlide('form-job-offer-delivery', 'form-job-offer-delivery-display')"
              v-model="delivery"
              required>
          </div>
        </div>
        <div class="form-row mx-0 mt-2 col-12">
          <div class="col-6 p-0 small">{{ $t('job.offer.delivery.slide.min') }}</div>
          <div class="col-6 p-0 text-right small">{{ $t('job.offer.delivery.slide.max') }}</div>
        </div>
        <small class="form-text text-muted">{{ $t('job.offer.delivery.info') }}</small>
      </div>
      <div class="form-row mt-3">
        <label for="form-job-offer-reason">{{ $t('job.offer.message.label') }}</label>
        <textarea class="form-control"
                  id="form-job-offer-reason"
                  rows="4"
                  v-model="message"
                  :placeholder="$t('job.offer.message.placeholder')"
                  v-bind:class="{ 'border-danger': validation.message }"
                  required
        ></textarea>
        <div class="small col-12 mt-1 justify-content-between d-flex">
          <small class="text-muted">{{ $t('job.offer.message.info') }}</small>
          <p class="text-center"><span v-bind:class="getClassForMessageCharacter">{{ message.length }}</span> {{ $tc('job.offer.message.character', message.length) }}</p>
        </div>

      </div>
    </div>
  </b-modal>
</template>

<script>
import apiService from '../../apiService'

export default {
  name: 'JobOffer',
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      delivery: 1,
      price: 0.00,
      revisions: this.job.revisions,
      message: '',
      errors: [],
      validation: {
        delivery: null,
        price: null,
        revisions: null,
        message: null
      }
    }
  },
  methods: {
    init () {
      this.message = ''
      this.delivery = 1
      this.price = null
      this.revisions = 1
      this.changeSlide('form-job-offer-delivery', 'form-job-offer-delivery-display')
    },
    reset () {
      this.validation = {
        delivery: null,
        price: null,
        revisions: null,
        message: null
      }
      this.delivery = 1
      this.errors = []
    },
    async handleSubmit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.handleValidation()) {
        const postData = {
          'price': this.price,
          'revisions': this.revisions,
          'message': this.message,
          'delivery': this.delivery * 24
        }
        await apiService.offerJob(this.job.hash, postData).then(() => {
          postData.job_hash = this.job.hash
          this.$emit('job-offer-submitted', postData)
          this.$tracker.sellerMakeOffer('jobOfferMade', {
            'ecommerce': {
              'products': [{
                'item_name': this.job.title,
                'price_from': this.job.price_from,
                'price_to': this.job.price_to,
                'item_id': this.job.hash,
                'price': this.price,
                'revisions': this.revisions,
                'delivery': this.delivery * 24
              }]
            }
          })
          this.$bvModal.hide('job-offer-modal')
          this.$swal({
            title: this.$t('job.offer.submit_success.title'),
            text: this.$t('job.offer.submit_success.description'),
            type: 'success',
            confirmButtonText: this.$t('job.offer.submit_success.confirm_button')
          })
        }).catch(() => {
        })
      }
    },
    handleValidation () {
      const priceRegex = /[(0-9)+.?(0-9)*]+/igm
      this.price = parseFloat(priceRegex.exec(this.price)).toFixed(2)

      // Reset validation
      this.validation = {
        delivery: null,
        price: null,
        revisions: null,
        message: null
      }

      this.errors = []
      if (isNaN(this.price) || this.price < 1 || this.price > 500) {
        this.validation.price = true
        this.errors.push(this.$t('job.offer.validation.price'))
      }

      if (isNaN(this.revisions) || this.revisions < 1) {
        this.validation.revisions = true
        this.errors.push(this.$t('job.offer.validation.revisions'))
      }

      if (this.message.length < 10) {
        this.validation.message = true
        this.errors.push(this.$t('job.offer.validation.message'))
      }

      return this.errors.length > 0
    },
    changeSlide (id, rangeId) {
      const target = document.getElementById(id)
      const rangeV = document.getElementById(rangeId)
      target.style.setProperty('--value', target.value)
      target.style.setProperty('--min', target.min === '' ? '0' : target.min)
      target.style.setProperty('--max', target.max === '' ? '100' : target.max)

      const newValue = Number((target.value - target.min) * 100 / (target.max - target.min))
      const newPosition = 10 - (newValue * 0.2)
      rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`
    }
  },
  computed: {
    getClassForMessageCharacter () {
      if (this.message.length < 10) {
        return 'text-danger'
      }
      if (this.message.length >= 10 && this.message.length < 100) {
        return 'text-warning'
      }
      return 'text-success'
    }
  }
}
</script>

<style scoped lang="scss">
.range-wrap {
  position: relative;
  top: 20px;
}

.range-value {
  position: absolute;
  top: -55%;
}

.bubble {
  white-space: nowrap;
  width: auto;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #182934;
  color: #fff;
  font-size: 12px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
}

.bubble:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid #182934;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}

input[type=range].range {
  height: 2.2em;
  -webkit-appearance: none;

  &.slider-progress {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));

    &::-webkit-slider-runnable-track {
      background: linear-gradient(#f07f13, #f07f13) 0/var(--sx) 100% no-repeat, #efefef;
    }

    &::-moz-range-track {
      height: Max(calc(1em - 1px - 1px), 0px);
      background: linear-gradient(#f07f13, #f07f13) 0/var(--sx) 100% no-repeat, #efefef;
    }

    &::-ms-fill-lower {
      height: Max(calc(1em - 1px - 1px), 0px);
      border-radius: 0.5em 0 0 0.5em;
      margin: -1px 0 -1px -1px;
      background: #f07f13;
      border: 1px solid #b2b2b2;
      margin: -1px 0 -1px -1px;
      border-right-width: 0;
    }
  }

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    background: #182934;
    border: none;
    box-shadow: 0 0 2px black;
    margin-top: calc(max(1.5em * 0.5, 0px) - 2em * 0.5);
  }

  &::-webkit-slider-runnable-track {
    height: 0.5em;
    border: 1px solid #b2b2b2;
    border-radius: 0.5em;
    background: #efefef;
    box-shadow: none;
  }

  &::-moz-range-thumb {
    width: 2em;
    height: 2em;
    border-radius: 1em;
    background: #182934;
    border: none;
    box-shadow: 0 0 2px black;
  }

  &::-moz-range-track {
    border: 1px solid #b2b2b2;
    border-radius: 0.5em;
    background: #efefef;
    box-shadow: none;
  }

  &::-ms-fill-upper {
    background: transparent;
    border-color: transparent;
  }

  &::-ms-fill-lower {
    height: Max(calc(1em - 1px - 1px), 0px);
    background: transparent;
    border-color: transparent;
  }

  &::-ms-thumb {
    width: 2em;
    height: 2em;
    border-radius: 1em;
    background: #182934;
    border: none;
    box-shadow: 0 0 2px black;
    margin-top: 0;
    box-sizing: border-box;
  }

  &::-ms-track {
    height: 1em;
    border-radius: 0.5em;
    background: #efefef;
    border: 1px solid #b2b2b2;
    box-shadow: none;
    box-sizing: border-box;
  }

  &:hover {
    &::-ms-track {
      background: #e5e5e5;
      border-color: #9a9a9a;
    }
  }

  &:active {
    &::-ms-track {
      background: #f5f5f5;
      border-color: #c1c1c1;
    }
  }
}
</style>
