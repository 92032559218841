<template>
  <div class="d-flex justify-content-between w-100">
    <div class="list-box-avatar d-flex justify-content-center align-items-center col-2 px-0 position-relative">
      <div class="position-relative">
        <img class="border-0 round" v-if="currentUser === message.to_username ? message.from_photo : message.to_photo" :data-src="formatProfilePhoto(currentUser === message.to_username ? message.from_photo : message.to_photo)">
        <div v-else class="avatar-fallback d-flex justify-content-center align-items-center round p-3">
          <i class="fa-solid fa-user fa-inverse text-light" />
        </div>
        <div v-if="message.unread > 0 && !hideUnread" class="position-absolute new-message-icon">
          {{ message.unread }}
        </div>
      </div>
    </div>
    <div class="list-box-content text-left ml-0 col-10 px-0 pl-2">
      <div class="">
        <div class="d-flex align-items-center justify-content-between user-timestamp">
          <div class="d-flex align-items-center flex-row username">
            <div v-if="checkIfOnline()" class="mr-1 online"></div>
            <div class="message-list-user font-weight-bold">{{ currentUser === message.to_username ? message.from_username : message.to_username }}</div>
          </div>
          <div class="message-list-hash">
            <span class="small">{{longerThenWeek ? date(message.last_message_received) : moment(message.last_message_received).fromNow() }}</span> |
            <span v-if="message.is_order"><i class="fa-solid fa-basket-shopping text-primary" aria-hidden="true" /></span>
            <span v-else-if="message.custom_status !== null"><i class="fa-solid fa-basket-shopping" aria-hidden="true" /></span>
            <span v-else-if="message.job_hash !== null"><i class="fa-solid fa-briefcase"></i></span>
            <span v-else><i class="fa-solid fa-envelope" aria-hidden="true" /></span>
          </div>
        </div>
        <div class="message-list-content d-flex">
          <span v-if="message.last_message !== null">
            {{ message.last_message }}
          </span>
          <span v-else>
            <i class="fa-solid fa-paperclip mr-1" aria-hidden="true" />
            {{ $t('attachment') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '../../helper'

export default {
  name: 'InboxItem',
  props: {
    message: [Object, Array],
    currentUser: String,
    toLastActive: String,
    fromLastActive: String,
    withinLastSeconds: Number,
    hideUnread: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      intervalTimer: null,
      longerThenWeek: false
    }
  },
  mounted () {
    this.startTimer()
  },
  created () {
    this.$emit('created')
  },
  methods: {
    formatProfilePhoto (img) {
      return helper.formatCloudinaryUrl(img, 'profile')
    },
    startTimer () {
      const duration = this.moment.duration(this.moment().diff(this.moment(this.message.last_message_received)))
      if (duration.days() > 7 || duration.months() >= 1 || duration.years() >= 1) {
        this.longerThenWeek = true
      } else if (duration.days() < 1) {
        let timer = 30000
        if (duration.hours() >= 1) {
          timer = 1800000
        }
        this.intervalTimer = setInterval(() => {
          this.$forceUpdate()
        }, timer)
      }
    },
    date (date) {
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    reload () {
      clearInterval(this.intervalTimer)
      this.startTimer()
      this.$forceUpdate()
    },
    checkIfOnline () {
      return helper.isUserOnline(this.currentUser === this.message.to_username ? this.fromLastActive : this.toLastActive, this.withinLastSeconds)
    }
  },
  computed: {
  },
  destroyed () {
    clearInterval(this.intervalTimer)
  }
}
</script>

<style lang="scss" scoped>
  @mixin lg-xl {
    @media (min-width: #{$breakpoint-lg}) and (max-width:  #{$breakpoint-xl}) {
      @content;
    }
  }
  .online {
    width: 8px;
    height: 8px;
    background: green;
    border-radius: 50%;
  }
    .align-items-center {
      align-items: normal;
    }
    .user-timestamp {
      flex-flow: column-reverse!important;
      display: block;
      .username {
        text-align: left;
        width: 100%;
        flex-flow: column;
      }
      .message-list-hash {
        text-align: right;
        width: 100%;
      }
      .list-group {
        height:109px!important;
      }
      .message-list .list-group {
        height: auto;
      }
    }
  @include xs-up {
    .user-timestamp {
      flex-flow: inherit!important;
      display: block;
    }
    .align-items-center {
      align-items: center;
    }
  }
  @include lg-xl {
    .user-timestamp {
      display: block;
    }
    .align-items-center {
      align-items: center;
    }
    .message-list-user {
      flex-flow: column;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
    }
  }
</style>
