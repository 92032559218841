<template>
  <div class="inbox-container h-100" v-bind:class="{ 'show': displayContainer }">
    <div class="p-4 border-bottom rounded ">
      <h4 class="font-weight-bold m-0">{{ title }}</h4>
      <span class="small">
        <span class="font-weight-bold">{{ totalNewMessages }}</span> {{ $tc('inbox_new_message', totalNewMessages) }}
      </span>
      <div class="mt-2" v-if="displaySort">
        <div class="btn-group btn-group-sm" role="group">
          <button type="button" class="btn" :class="[sortBy === 'default' ? 'btn-primary': 'btn-outline-primary']" @click="updateSort('default')">{{ $t('inbox_sort.default') }}</button>
          <button type="button" class="btn" :class="[sortBy === 'newest' ? 'btn-primary': 'btn-outline-primary']" @click="updateSort('newest')">{{ $t('inbox_sort.newest') }}</button>
          <button type="button" class="btn" :class="[sortBy === 'unread' ? 'btn-primary': 'btn-outline-primary']" @click="updateSort('unread')">{{ $t('inbox_sort.unread') }}</button>
        </div>
      </div>
    </div>
    <div v-if="!displayLoader">
      <div class="mx-auto my-5">
        <b-spinner large label="Large Spinner"/>
        <div>{{ $t('loading_inbox') }}</div>
      </div>
    </div>
    <div v-else class="message-list list-group border-0" @scroll="onScroll">
      <div v-for="(item, index) in items" :key="index" class="cursor-pointer" @click="itemClicked(item)">
        <div class="list-group-item list-group-item-action border-0"
             :class="{ 'active' : activeHash === item.hash, 'not-seen' : item.unread > 1 }"
             v-lazy-container="{ selector: 'img' }">
          <inbox-item
            :message="getMessage(item)"
            :current-user="getLoggedInUser"
          />
        </div>
      </div>
      <div v-if="pagination.per_page < pagination.total" class="d-inline-flex justify-content-center load-more p-2 my-4">
        <b-spinner class="mr-2" small label="Small Spinner"/>
        <span>{{ $t('loading_more') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import InboxItem from '../../Message/InboxItem'
export default {
  name: 'Inbox',
  components: { InboxItem },
  props: {
    title: {
      type: String,
      required: true
    },
    displayContainer: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    displaySort: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    },
    displayLoader: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    activeHash: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      sortBy: 'default',
      totalNewMessages: 0
    }
  },
  methods: {
    updateSort (value) {
      this.sortBy = value
      this.$emit('inbox-sort', value)
    },
    itemClicked (item) {
      this.$emit('inbox-item-clicked', item)
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.pagination.per_page < this.pagination.total && Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        this.$emit('inbox-item-load-more')
      }
    },
    getMessage (inbox) {
      return {
        to_username: inbox.to_username,
        to_photo: inbox.to_photo,
        from_username: inbox.from_username,
        from_photo: inbox.from_photo,
        unread: inbox.unread,
        last_message_received: inbox.last_message_received,
        last_message: inbox.last_message,
        is_order: inbox.is_order,
        custom_status: inbox.custom_status,
        job_hash: inbox.job_hash
      }
    }
  },
  computed: {
    getLoggedInUser () {
      return localStorage.getItem('username')
    }
  }
}
</script>

<style lang="scss" scoped>
.inbox-container {
  background: #FFFFFF;
  border-radius: 7px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.0675);
}

.load-more {
  border: 1px solid $grey-border-and-text;
  border-radius: 5px;
}

::v-deep .message-list {
  font-size: 12px;
  color: $grey-dark-text;
  max-height: 508px;

  display: block;
  overflow-y: auto;

  .list-group-item {
    border-radius: unset !important;
    border-bottom: 1px solid $grey-border-and-text !important;
    background: transparent;
    cursor: pointer;
    border-left: 2px solid transparent !important;

    .round {
      border-radius: 50% !important;
    }

    .list-box-avatar img, .avatar-fallback {
      min-height: 42px;
      min-width: 42px;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      overflow: hidden;
      padding: 0;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    }

    .avatar-fallback {
      background: $grey-dark-text;
      font-size: 1.25rem;
    }

    .message-list-user {
      font-size: 1rem;
      color: $brand-denim;
    }

    .new-message-icon {
      background: $brand-sky;
      top: -2px;
      right: -2px;
      padding: 0 4px;
      border-radius: 20px;
      font-size: 10px;
      color: white;
    }

    &:hover {
      background-color: $grey-bg;
      box-shadow: none;
    }
    &.not-seen {
      border-left: 2px solid $brand-tangerine !important;
    }
    &.active {
      color: $grey-dark-text;
      background-color: $grey-bg;
      border-left: 2px solid $brand-sky !important;
    }
  }
}
</style>
