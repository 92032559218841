<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center text-center justify-content-center mt-4 mb-3">
      <h1 class="m-0">{{ $t('job.board.title') }}</h1>
      <span class="badge badge-pill badge-primary ml-2">BETA</span>
    </div>

    <div class="d-flex justify-content-center justify-content-md-end mb-4">
      <b-dropdown :text="$t('job.board.label.sort') + ' ' + $t('job.board.button.sort')[this.order].toLowerCase() + ' '" class="mr-2 sort-by">
        <b-dropdown-item @click="handleSort('desc')">{{ $t('job.board.button.sort')['desc'] }}</b-dropdown-item>
        <b-dropdown-item @click="handleSort('asc')">{{ $t('job.board.button.sort')['asc'] }}</b-dropdown-item>
      </b-dropdown>
      <router-link :to="{ name: 'task_or_job', params: { referrer: 'job_board' } }" class="btn btn-primary px-3 rounded-pill">{{ $t('job.my_account.button.create') }}</router-link>
    </div>

    <template v-if="!isLoaded">
      <div class="mx-auto my-5">
        <b-spinner large label="Large Spinner"/>
        <div>{{ $t('loading') }}</div>
      </div>
    </template>
    <template v-else-if="jobs.length > 0">
      <job-card :job="job" v-for="(job) in jobs" :key="job.hash"
               :is-seller="isSeller"
               class="job-container mb-4"
               @job-contact-client="openContact"
               @job-open-offer="openOffer"
      />
    </template>
    <div v-else>
      <p>{{ $t('job.board.no_results') }}</p>
      <i class="fa-5x fa-solid fa-bed"></i>
    </div>

    <message-popup
      @message-pop-up-success="updateMessageButton"
      :user-hash="contact.user_hash"
      :user-last-active="contact.last_active"
      :user-name="contact.username"
      :user-image="contact.profile"
      :post-type="{ hash: contact.job_hash, type: 'job' }" />

    <job-offer
      @job-offer-submitted="handleOffer"
      :job="job"
    />

  </div>
</template>

<script>
import jobCard from '../components/Job/JobCard'
import apiService from '../apiService'
import MessagePopup from '../components/Modal/MessagePopup'
import JobOffer from '../components/Job/JobOffer'

export default {
  name: 'jobBoard',
  components: { JobOffer, MessagePopup, jobCard },
  data () {
    return {
      isLoaded: false,
      jobs: [],
      isSeller: false,
      contact: {
        job_hash: '',
        user_hash: '',
        username: '',
        profile: '',
        last_active: null
      },
      job: {
        price_to: 0.0,
        price_from: 0.0
      },
      page: 1,
      order: 'desc'
    }
  },
  async mounted () {
    await this.getJobList()
    if (this.$store.getters.hasRole('ROLE_SELLER') && this.$store.getters.getVerification() === 1) {
      apiService.getTotals(this.$store.getters.getUsername()).then((response) => {
        this.isSeller = parseInt(response.headers['x-total-tasks']) > 0
      }).catch(() => {
        this.isSeller = false
      })
    }

    this.$tracker.viewJobBoard('jobBoardView', {
      user: {
        seller: this.$store.getters.hasRole('ROLE_SELLER')
      }
    })
  },
  methods: {
    async getJobList () {
      this.isLoaded = false
      await apiService.getJobListing(this.page, 24, this.order).then((resp) => {
        this.jobs = resp.data.data.results
        this.isLoaded = true
      })
    },
    openContact (info) {
      this.contact = info
      this.$bvModal.show('contactModal')
    },
    openOffer (info) {
      this.job = info
      this.$bvModal.show('job-offer-modal')
    },
    handleOffer (data) {
      this.jobs.find((object, index) => {
        if (object.hash === data.job_hash) {
          this.jobs[index].job_offer.push({
            status: 1
          })
          return true
        }
      })
    },
    handleSort (order) {
      this.page = 1
      this.order = order
      this.getJobList()
    },
    updateMessageButton () {
      this.jobs.find((object, index) => {
        if (object.hash === this.contact.job_hash) {
          this.jobs[index].job_offer.push({
            status: 0
          })
          return true
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .job-scroll-description {
  overflow: auto;
  overflow-y: scroll;
  max-height: 265px;
}

.job-container {
  border-radius: 1.25rem;
}

::v-deep .job-container ul li {
  list-style-type: disc!important;
}

::v-deep .job-container ol li {
  list-style-type: auto!important;
}

::v-deep .sort-by {

  .dropdown-toggle {
    border-radius: 50rem !important;
    color: black;
    background: transparent;
    border: none;
    &:active {
      color: black;
      background: transparent;
      box-shadow: none !important;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-menu {
    background-color: white;
    padding: 8px 8px!important;
    border: none;
    margin-top: 10px;
    border-radius: 10px !important;
    z-index: 10;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.15);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    right:0;
    min-width: 200px;
    max-height: 420px;
    background-clip: unset;

    li {
      padding: 8px 8px !important;
      border-bottom: 2px #F5F5F5 solid !important;

      &:hover {
        background-color: #EEEEEE !important;
      }
      .dropdown-item {
        font-size: 0.9rem;

        &:active {
          color: unset;
        }

        &:hover {
          background-color: unset !important;
        }
      }

      &:last-of-type {
        border-bottom: none !important;
      }
    }
  }
}

</style>
