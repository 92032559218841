<template>
  <div class="d-flex flex-col flex-sm-row w-100 justify-content-end">
    <div v-if="isSeller && !isOwner" class="mr-sm-2">
      <button v-if="job.job_offer.length > 0 && [1,2,3].includes(job.job_offer[0].status)" @click="handleOffer(job)" class="btn w-100 order-1 btn-primary rounded-pill mt-3 mt-sm-0 ml-sm-3 px-3">{{ $t('job.card.button.view_offers') }}</button>
      <button v-else-if="!buttonDisplay.expired" @click="handleOffer(job)" class="btn w-100 order-1 btn-primary rounded-pill mt-3 mt-sm-0 mr-0 ml-sm-3 px-3">{{ $t('job.card.button.make_offer') }}</button>
    </div>
    <button v-if="isSeller && !isOwner" class="btn order-1 btn-dark rounded-pill mt-2 mt-sm-0 mr-0 ml-sm-3 px-3" @click="handleContact(job)">
      <template v-if="job.job_offer.length === 0">{{ $t('job.card.button.contact_owner', { 'username': job.user.username }) }}</template>
      <template v-else>{{ $t('job.card.button.view_message') }}</template>
    </button>
    <button v-if="isOwner && job.job_offer.length" class="btn order-1 btn-primary rounded-pill mt-2 mt-sm-0 mr-0 ml-sm-3 px-3" @click="handleJob(job, true)">
      {{ $t('job.card.button.view_all_offers') }}
    </button>
    <button v-if="buttonDisplay.viewJob" class="btn order-1 btn-outline-dark rounded-pill mt-2 ml-sm-3 mt-sm-0 px-3" @click="handleJob(job)">{{
        $t('job.card.button.view_job')
      }}
    </button>
  </div>
</template>

<script>

export default {
  name: 'ButtonAction',
  props: {
    job: {
      type: Object,
      required: true
    },
    isSeller: {
      type: Boolean,
      required: false
    },
    buttonDisplay: {
      type: Object,
      required: false,
      default () {
        return { viewJob: false }
      }
    }
  },
  methods: {
    handleContact (job) {
      if (!this.handleScrollTo()) {
        if (job.job_offer.length && job.job_offer[0].status >= 0) {
          this.$router.push({ name: 'jobs_view', params: { hash: job.hash } })
        } else {
          const user = job.user
          this.$emit('job-contact-client', {
            job_hash: job.hash,
            user_hash: user.hash,
            username: user.username,
            profile: user.photo_path,
            last_active: user.last_active
          })
        }
      }
    },
    handleOffer: function (job) {
      if (!this.handleScrollTo()) {
        if (job.job_offer.length && job.job_offer[0].status > 0) {
          this.$router.push({ name: 'jobs_view', params: { hash: job.hash }, hash: '#offer-messages' })
        } else {
          this.$emit('job-open-offer', job)
        }
      } else {
        if (job.job_offer.length && [0, 4].includes(job.job_offer[0].status)) {
          this.$emit('job-open-offer', job)
        }
      }
    },
    handleJob: function (job, viewOffers) {
      if (!this.handleScrollTo()) {
        if (viewOffers) {
          this.$router.push({ name: 'jobs_view', params: { hash: job.hash }, hash: '#offer-messages' })
        } else {
          this.$router.push({ name: 'jobs_view', params: { hash: job.hash } })
        }
      }
    },
    handleScrollTo () {
      const elements = document.getElementsByClassName('message-container')
      if (elements.length) {
        this.$emit('scrollToMessages')
        return true
      }
      return false
    }
  },
  computed: {
    isOwner () {
      return this.job.user.username === localStorage.getItem('username')
    }
  }
}
</script>
