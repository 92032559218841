<template>
  <div class="pb-5">
    <div v-if="job !== null">
      <h2 class="m-4">{{ $t('job.view.title', { 'job_title': job.title }) }}</h2>
      <div class="container-fluid">
        <b-alert variant="warning" v-if="hasExpired" show>{{ hasRole('ROLE_SELLER') ? $t('job.view.not_on_board_alert_seller') :  $t('job.view.not_on_board_alert_buyer')}}</b-alert>
        <div class="row">
          <div class="col-12 col-lg-8">
            <div class="card job-container">
              <div class="card-header bg-transparent border-bottom-0 pb-0">
                <h3 class="font-weight-bold p-2">{{ $t('job.view.description.title') }}</h3>
              </div>
              <div class="card-body text-left pt-2 job-description">
                <div class="mb-4 mb-sm-0" v-html="this.$sanitize(job.description, { allowedTags: ['br', 'strong', 'p', 'ul', 'ol', 'li', 'u', 'em', 'i'] })"></div>
              </div>
              <div class="card-footer bg-transparent border-top-0 flex-col flex-sm-row justify-content-sm-end">
                <router-link :to="{ name: this.$route.meta.back }" tag="button" class="btn btn-outline-dark rounded-pill mt-2 mt-sm-0 mr-sm-auto order-2 order-sm-0 px-3"><i
                  class="fa-solid fa-chevron-left fa-sm mr-1"></i> {{ $t('job.card.button.back') }}
                </router-link>
                <button-action
                  :job="job"
                  :is-seller="isSeller"
                  :button-display="{ viewMessage: offer === null, viewOffer: (offer === null) || [0].includes(offer.status), expired: hasExpired}"
                  @job-contact-client="openContact"
                  @job-open-offer="openOffer"
                  @scrollToMessages="scrollToMessages"
                />
              </div>
            </div>
          </div>

          <div class="col-12 mt-4 mt-lg-0 col-lg-4">
            <div class="row">
              <div class="col-12 mb-4" v-if="!isOwner && offer !== null && ((offer.status > 0) && (offer.status !== 4))">
                <div class="card">
                  <div class="card-header bg-transparent border-bottom-0 pb-0">
                    <h3 class="font-weight-bold p-2">{{ $t('job.view.your_offer.title') }}</h3>
                  </div>
                  <div class="card-body pt-0">
                    <div class="m-2 d-flex justify-content-between"><strong>{{ $t('job.view.your_offer.price') }}</strong> {{ displayPrice(offer.price, 'USD') }}</div>
                    <div class="m-2 d-flex justify-content-between"><strong>{{ $t('job.view.your_offer.revisions') }}</strong> {{ offer.revisions }}</div>
                    <div class="m-2 d-flex justify-content-between"><strong>{{ $t('job.view.your_offer.delivery') }}</strong>
                      {{ $tc('job.view.your_offer.day', (offer.delivery / 24)) }}
                    </div>
                    <div>
                      <button class="btn btn-danger" @click="handleCancelOffer({ hash: job.hash, offer_hash: offer.hash })" v-if="[1,2].includes(offer.status)">
                        {{ $t('job.view.your_offer.cancel') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="card">
                  <div class="card-header bg-transparent border-bottom-0 pb-0">
                    <h3 class="font-weight-bold p-2">{{ $t('job.view.summary.title') }}</h3>
                  </div>
                  <div class="card-body text-left pt-0">
                    <div class="mb-4">
                      <div class="m-2 d-flex justify-content-between"><strong>{{ $t('job.view.summary.status') }}</strong> {{ getStatus }}</div>
                      <div class="m-2 d-flex justify-content-between"><strong>{{ $t('job.view.summary.date_expiry') }}</strong> {{ moment(job.date_expires).format('LL') }}</div>
                      <div class="m-2 d-flex justify-content-between"><strong>{{ $t('job.view.summary.budget') }}</strong> {{ displayPrice(job.price_from, 'USD') }} -
                        {{ displayPrice(job.price_to) }}
                      </div>
                    </div>
                    <carousel class="w-100" :images="buildImages" :taskHash="job.hash"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr v-if="!hasExpired || (hasExpired && message.total !== 0)" class="my-4"/>

        <div ref="offerMessages" class="row pr-lg-2" v-bind:class="{ 'justify-content-center': !isOwner }">
          <template v-if="isOwner && loaders.inbox && loaders.messages && message.total === 0 && !hasExpired">
            <div class="mx-auto pt-5">
              <h3>{{ $t('job.view.no_offers') }}</h3>
              <i class="fa-4x mb-3 fa-solid fa-suitcase"/>
            </div>
          </template>
          <template v-else-if="message.total !== 0">
            <div v-if="isOwner" class="col-12 col-lg-4 header-container mb-4 mb-lg-0">
              <inbox
                title="My Offers"
                :displayLoader="loaders.inbox"
                :display-sort="false"
                :items="this.inboxes"
                :pagination="this.pagination"
                :active-hash="this.currentIndexHash"
                :display-container="this.displayInboxContainer"
                @inbox-item-clicked="handleItemClick"
                @inbox-item-load-more="handleLoadMore"
              />
            </div>
            <div class="col-12 col-lg-8 message-container mx-auto px-0" v-if="this.offer || isOwner" v-bind:class="{ 'show': !displayInboxContainer }">
              <div class="h-100" v-if="loaders.messages">
                <transition name="fade">
                  <message-inbox v-if="message.read.length > 0"
                                 :display-back-button="isOwner"
                                 :hash="messageThread.hash"
                                 :username="getLoggedInUser"
                                 :from-username="messageThread.from_username"
                                 :from-last-active-date="messageThread.from_last_active"
                                 :to-username="messageThread.to_username"
                                 :to-last-active-date="messageThread.to_last_active"
                                 :within-last-seconds="300000"
                                 :messages="message.read"
                                 :unread-message="message.unread"
                                 :lock="messageThread.locked"
                                 :job-details="getJobDetails"
                                 :display-inbox="handleBackButton"
                                 @job-button-cancel-offer="handleCancelOffer"
                                 @job-button-open-offer="openOffer"
                  />
                </transition>
              </div>
              <div v-else class="d-flex justify-content-center align-items-center h-100">
                <div class="d-flex flex-column align-items-center py-5">
                  <b-spinner large label="Large Spinner"/>
                  <div>{{ $t('message_loading') }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>

      </div>

      <message-popup
        @message-pop-up-success="updateButtons"
        :user-hash="job.user.hash"
        :user-last-active="job.user.last_active"
        :user-name="job.user.username"
        :user-image="job.user.photo_path"
        :post-type="{ hash: job.hash, type: 'job' }"
      />

      <job-offer :job="job"
                 @job-offer-submitted="updateButtons"
      />
    </div>
  </div>
</template>

<script>
import carousel from '../../components/Global/CardCarousel'
import apiService from '../../apiService'
import helper from '../../helper'
import MessageInbox from '../../components/MyAccount/Inbox/MessageInbox'
import Inbox from '../../components/MyAccount/Inbox/Inbox'
import ButtonAction from '../../components/Job/ButtonAction'
import MessagePopup from '../../components/Modal/MessagePopup'
import JobOffer from '../../components/Job/JobOffer'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Details',
  components: { JobOffer, MessagePopup, ButtonAction, Inbox, MessageInbox, carousel },
  data () {
    return {
      job: null,
      offer: null,
      jobDetails: null,
      currentIndexHash: '',
      inboxes: [],
      currentCount: 1,
      currentPrePage: 7,
      pagination: {
        page: 1,
        per_page: 0,
        total: 0
      },
      messageThread: {},
      message: {
        total: 0,
        read: [],
        unread: []
      },
      loaders: {
        inbox: false,
        messages: false
      },
      displayInboxContainer: false,
      isSeller: false,
      hasExpired: null
    }
  },
  async created () {
    await this.getJob()
    this.hasExpired = this.moment(this.job.date_expires).diff(this.moment()) < 0
    if (this.$store.getters.hasRole('ROLE_SELLER') && this.$store.getters.getVerification() === 1) {
      apiService.getTotals(this.$store.getters.getUsername()).then((response) => {
        this.isSeller = parseInt(response.headers['x-total-tasks']) > 0
      }).catch(() => {
        this.isSeller = false
      })
    }

    this.$tracker.viewJobBoard('jobView', {
      'ecommerce': {
        'products': [{
          'item_name': this.job.title,
          'price_from': this.job.price_from,
          'price_to': this.job.price_to,
          'item_id': this.job.hash
        }]
      }
    })
  },
  methods: {
    ...mapActions([
      'setNewOffers'
    ]),
    displayPrice (price) {
      return helper.getDisplayPrice(price, 'USD')
    },
    localeDateFormat (date) {
      return (new Date(date)).toLocaleDateString()
    },
    async getJob () {
      await apiService.getJob(this.$route.params.hash).then(async (resp) => {
        this.job = resp.data.data
        if (this.isOwner) {
          await this.getInbox()
          if (this.inboxes.length) {
            this.currentIndexHash = this.inboxes[0].hash
            this.messageThread = this.inboxes[0]
            await this.getMessage(this.currentIndexHash)
          } else {
            this.loaders.messages = true
          }
        } else {
          if (this.job.job_offer) {
            this.offer = this.job.job_offer[0] || null

            if (this.offer) {
              this.currentIndexHash = this.offer.message_thread.hash
              await apiService.getThreadInfo(this.offer.message_thread.hash).then((resp) => {
                this.messageThread = resp.data.data
              })
              await this.getMessage(this.messageThread.hash)
            }
          }
        }
        if (this.$route.hash === '#offer-messages') {
          this.scrollToMessages()
        }
      })
    },
    async getMessage (hash) {
      this.loaders.messages = false
      await apiService.getMessages(hash).then((response) => {
        let lastHash = null
        response.data.data.forEach(function (message) {
          if (message.hash === lastHash) {
            message.repeat = true
          } else {
            message.repeat = false
            lastHash = message.hash
          }
        })

        this.message.total = response.data.data.length
        this.message.read = response.data.data
        this.message.unread = response.data.data.filter(function (message) {
          return message.read < 1
        })
        this.loaders.messages = true
      })
    },
    async getInbox () {
      return apiService.getInbox(this.pagination.page, this.currentPrePage, null, 5, this.job.hash).then((resp) => {
        this.inboxes = resp.data.data.results
        this.pagination = resp.data.data.pagination
        this.loaders.inbox = true
      })
    },
    async handleItemClick (item) {
      this.messageThread = item
      this.displayInboxContainer = false
      await this.getMessage(item.hash)
      this.currentIndexHash = item.hash
    },
    async handleLoadMore () {
      this.currentPrePage = ++this.currentCount * this.currentPrePage
      await this.getInbox()
    },
    openContact () {
      this.$bvModal.show('contactModal')
    },
    openOffer () {
      this.$bvModal.show('job-offer-modal')
    },
    async updateButtons () {
      await this.getJob()
    },
    handleBackButton () {
      this.displayInboxContainer = true
    },
    handleCancelOffer (jobOffer) {
      this.$swal({
        title: this.isOwner ? this.$t('job.view.cancel_offer.owner.title') : this.$t('job.view.cancel_offer.seller.title'),
        text: this.isOwner ? this.$t('job.view.cancel_offer.owner.description') : this.$t('job.view.cancel_offer.seller.description'),
        type: 'warning',
        showCancelButton: true,
        preConfirm: (result) => {
          if (result === true) {
            return apiService.cancelOffer(jobOffer.hash, jobOffer.offer_hash)
              .then(() => {
              })
              .catch((error) => {
                this.$swal.showValidationMessage(
                  error.response.data.error_message
                )
              })
          }
        }
      }).then(async (result) => {
        if (result.value === true) {
          const hashArr = this.getNewOffers().hashes
          hashArr.splice(hashArr.indexOf(this.job.hash), 1)
          this.isOwner && this.setNewOffers({ count: this.getNewOffers().count - 1, hashes: hashArr })
          this.$swal({
            title: this.isOwner ? this.$t('job.view.cancel_offer.owner.success_title') : this.$t('job.view.cancel_offer.seller.success_title'),
            text: this.isOwner ? this.$t('job.view.cancel_offer.owner.success_description') : this.$t('job.view.cancel_offer.seller.success_description'),
            type: 'success'
          })
          await this.getJob()
        }
      })
    },
    scrollToMessages () {
      if (this.message.total !== 0) {
        window.scrollTo({ top: this.$refs.offerMessages.getBoundingClientRect().top + window.scrollY - 120, behavior: 'smooth' })
      }
    }
  },
  computed: {
    ...mapGetters(['getNewOffers', 'hasRole']),
    buildImages () {
      return this.job.job_assets.map(function (asset) {
        return { 'src': helper.formatCloudinaryUrl(asset.path, 'task'), 'type': asset.type }
      })
    },
    getStatus () {
      if (this.offer !== null) {
        if (this.isOwner) {
          return this.$t('job.view.status.offer_made')
        }

        return this.$t('job.view.status.' + this.offer.status.toString())
      }

      return this.$t('job.view.status.no_offers')
    },
    getLoggedInUser () {
      return localStorage.getItem('username')
    },
    getJobDetails () {
      return {
        'page': 'job_view',
        'hash': this.messageThread.job_hash,
        'offer_hash': this.messageThread.job_offer_hash,
        'status': this.messageThread.job_status,
        'due_date': this.messageThread.job_due_date,
        'date_expires': this.messageThread.job_date_expires,
        'price': this.messageThread.job_price,
        'revisions': this.messageThread.job_revisions,
        'delivery': this.messageThread.job_delivery,
        'description': this.messageThread.job_description,
        'price_from': this.messageThread.job_budget_from,
        'price_to': this.messageThread.job_budget_to
      }
    },
    isOwner () {
      return this.job.user.username === this.getLoggedInUser
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 991px) {
  .inbox-container:not(.show) {
    display: none
  }

  .message-container:not(.show) {
    display: none;
  }
  .message-container:has(.show) {
    margin: auto;
  }

  .message-container {
    flex: 0 0 91.666667% !important;
    max-width: 100% !important;
  }
}

h3 {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 40%;
    bottom: 0;
    background: $task-light-grey;
    left: 0;
    margin: 0 30%;
  }
}

.message-container {
  border: 1px solid #ddd;
  border-radius: 7px;
  height: 79vh;
  max-height: 585px;
  background: white;
}

::v-deep .message-chat-history {
  margin-bottom: auto;
  flex: 1;

  display: block;
  overflow-y: auto;
}

::v-deep .message-chat-history-list {
  max-height: 508px;

  .pagination {
    .page-item {
      padding: 0;
    }
  }
}

::v-deep .message-chat {
  max-height: 580px;
}

::v-deep .job-container ul li {
  list-style-type: disc!important;
}

::v-deep .job-container ol li {
  list-style-type: auto!important;
}
</style>
